img-60 {
	width: 60%;
}

@media (min-width: 768px) .navbar-vertical.navbar-expand-md .navbar-brand-img {
	max-height: 100% !important;
}
.navbar-vertical .navbar-brand-img,
.navbar-vertical .navbar-brand > img {
	max-height: 100% !important;
}

.color-black {
	color: black;
}

pre {
	background: #f4f4f4;
	border: 1px solid #ddd;
	border-left: 3px solid #f36d33;
	color: #666;
	page-break-inside: avoid;
	font-family: monospace;
	font-size: 15px;
	line-height: 1.6;
	margin-bottom: 1.6em;
	max-width: 100%;
	overflow: auto;
	padding: 1em 1.5em;
	display: block;
	word-wrap: break-word !important;
}

li {
	list-style-type: none;
}

.code-snippet {
	background-color: rgb(45, 45, 45);
	border-radius: 7px;
	color: white;
	transition: all .25s;
	margin: auto;
	max-width: 1300px;
	min-width: 500px;
}

.code-snippet .nav-link {
	padding: 15px;
	box-sizing: border-box;
	border: none;
	border-bottom: 5px solid transparent;
	transition: border-bottom .25s;
}

.code-snippet .nav-link:hover {
	cursor: pointer;
	border: none;
	border-bottom: 5px solid #f36d33;
}

.code-snippet .nav-link.active {
	border-bottom: 5px solid #f36d33;
	background-color: rgb(45, 45, 45);
	color: white;
}

.code-snippet pre {
	border: none;
}

.loader {
	height: 100vh;
}

pre code {
	font-size: inherit;
    color: inherit;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    max-width: 100%;
    display: inline-block;
    white-space: break-spaces;
}


.btn-blocked {
	color: white;
	background-color: #B1B1B1;
	border-color: #B1B1B1;
}

.btn-blocked:hover {
	cursor: not-allowed;
	transform: none;
	box-shadow: none;
}

.unsubscribe:hover {
	color: white;
	background-color: red !important;
	border-color: red;
}

.btn-light {
	cursor: pointer;
}

.bg-black-transparent {
	background-color: rgb(0, 0, 0, 0.35);
}

.bottom-btn {
	position: absolute;
	bottom: 0;
	left: 0;
	margin: 10px;
	width: calc(100% - 20px);
}

.url-display {
	width: calc(100% - 142px);
}

.request-option-button {
	margin-left: 15px;
	width: 56px;
}
.cursor-pointer {
	cursor: pointer;
}

.active-subscription {
	border-top: 5px solid #f36d33 !important;
	background-image: linear-gradient(#fff0ea, white 40%);
}

.nav-bottom {
	position: absolute;
	bottom: 0;
	display: flex;
	flex-direction: column;
}

.nav-bottom .nav-item {
	margin-top: 10px;
	width: 100%;
}

.nav-bottom .nav-item:hover {
	background-color: #FAFAFA;
}